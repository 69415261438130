
import useLoginViewModel from '../viewModel/loginViewModel';
import LoginContainer from './component/LoginContainer';

function Login() {
  const { handleLogin } = useLoginViewModel()

  return (
    <LoginContainer handleLogin={handleLogin} />
  );
}

export default Login;
