import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useRecruitmentFilterStore } from "../../../store/recruitmentFilterStore";
// import { useRecruitmentFilterStore } from "@store/recruitmentFilterStore";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./filter.scss";
function Filter2({ toggleDrawer }) {
  const filter = useRecruitmentFilterStore((state) => state);
  const [searchText, setSearchText] = React.useState(filter.search);
  const [sortValue, setSortValue] = React.useState(filter.sortBy);
  const [startDate, setStartDate] = useState(filter.customStartDate);
  const [endDate, setEndDate] = useState(filter.customEndDate);
  const [radio, setRadio] = React.useState(false);
  const [periodValue, setPeriodValue] = useState(filter.period);
  const [clearAllState, setClearAllState] = useState(false);
  const [dateErrorMsg, setDateErrorMsg] = useState(false);
  const filterData = [
    {
      id: "item-period",
      value: "Last 30 Days",
    },
    {
      id: "item-period1",
      value: "MTD",
    },
    {
      id: "item-period2",
      value: "YTD",
    },
    {
      id: "item-period3",
      value: "ITD",
    },
    {
      id: "item-period4",
      value: "Custom",
    },
  ];
  let selectedFilters = [
    {
      title: "sorting",
      status: sortValue ? true : false,
      clear: () => {
        setSortValue("");
        if (searchText === "" && periodValue === "") {
          setClearAllState(true);
        }
      },
    },
    {
      title: "search",
      status: searchText ? true : false,
      clear: () => {
        setSearchText("");
        if (sortValue === "" && periodValue === "") {
          setClearAllState(true);
        }
      },
    },
    {
      title: "period",
      status: periodValue ? true : false,
      clear: () => {
        setPeriodValue("");
        if (searchText === "" && sortValue === "") {
          setClearAllState(true);
        }
      },
    },
  ];
  const radioChecked = (e) => {
    setPeriodValue(e.target.value);
    if (e.target.value === "Custom") {
      setRadio(true);
    } else {
      setRadio(false);
    }
  };

  const handleApplyFilter = (e) => {
    if (
      periodValue !== "Custom" ||
      (periodValue === "Custom" && startDate && endDate)
    ) {
      filter.setPeriod(periodValue);
      filter.setSearch(searchText);
      filter.setCustomStartDate(startDate);
      filter.setCustomEndDate(endDate);
      filter.setSortBy(sortValue);
      toggleDrawer("top", false)(e);
      setDateErrorMsg(false);
    } else {
      setDateErrorMsg(true);
    }
  };

  const [openStartCalendar, setOpenStartCalendar] = useState(false);
  const [openEndCalendar, setOpenEndCalendar] = useState(false);

  const sortingList = [
    {
      label: "Batch Name",
      value: "BatchName",
    },
    {
      label: "Application No.",
      value: "ApplicationNo",
    },
    {
      label: "Prospects Name",
      value: "ProspectsName",
    },
    {
      label: "Branch",
      value: "Branch",
    },
    {
      label: "Age",
      value: "Age",
    },
    {
      label: "Branch Code",
      value: "BranchCode",
    },
    {
      label: "Gender",
      value: "Gender",
    },
    {
      label: "Dm TMs Name",
      value: "DmTMsName",
    },
  ];

  useEffect(() => {
    if (clearAllState) {
      filter.setPeriod(periodValue);
      filter.setSearch(searchText);
      filter.setCustomStartDate(startDate);
      filter.setCustomEndDate(endDate);
      filter.setSortBy(sortValue);
      setClearAllState(false);
    }
    return () => {
      if (clearAllState) setClearAllState(false);
    };
  }, [clearAllState]);

  return (
    <>
      <div className="filter-comp">
        <div className="cta-wrap cm-visible-sm">
          <div className="bs-icon-text-sort-wrap ">
            <div
              className="text-title-wrap"
              onClick={toggleDrawer("right", false)}
            >
              <span className="icon icon-arrow-left"></span>
              <h1 className="cm-title-1">Filters</h1>
            </div>
            <button
              className="btn-cancel"
              onClick={toggleDrawer("right", false)}
            >
              <span className="text">Cancel</span>
            </button>
          </div>
        </div>
        <div className="cm-container">
          <div className="bs-filter">
            <div className="filter-wrap typ-filter2">
              <div className="all-selected-filter-cont">
                {(sortValue || searchText || periodValue) && (
                  <>
                    <h5 className="filter-title">ALL SELECTED FILTERS</h5>
                    <div className="selected-filters-wrap">
                      <ul className="list">
                        {selectedFilters.map((item, i) => {
                          return (
                            item.status && (
                              <li key={i} className="item">
                                <p className="tex-icon-wrap">
                                  <span className="text">{item.title}</span>
                                  <span
                                    onClick={item.clear}
                                    className="icon icon-Close"
                                  ></span>
                                </p>
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
                <div className="filter-form-wrap">
                  {/* <h5 className="filter-form-title">Select Filters</h5> */}
                  <div className="form-wrap">
                    <div className="sorting-wrap">
                      <div className="sort-cont">
                        <p className="text">SORT BY</p>
                        <div className="sort-list">
                          <div className="list grp1">
                            <FormControl className="radio-btn-wrap">
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={(e) => {
                                  setSortValue(e.target.value);
                                }}
                              >
                                {sortingList.map((item, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={item.value}
                                    checked={
                                      sortValue === item.value ? true : false
                                    }
                                    control={<Radio />}
                                    label={
                                      <React.Fragment>
                                        <span className="label">
                                          {item.label}
                                        </span>
                                      </React.Fragment>
                                    }
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="period-filter">
                      <div className="bs-form">
                        <div className="form-group">
                          <label className="form-label">Search</label>
                          <div className="field-wrap">
                            <TextField
                              required
                              id="outlined-basic"
                              variant="outlined"
                              name="batchName"
                              className="form-control"
                              type="text"
                              placeholder="Search anything here"
                              value={searchText}
                              onChange={(e) => {
                                setSearchText(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="period-cont">
                        <p className="text">PERIOD</p>
                        <div className="period-chip">
                          <ul className="period-list">
                            {filterData.map((item, i) => (
                              <li key={i} className="item">
                                <input
                                  type="radio"
                                  name="item-period"
                                  id={item.id}
                                  value={item.value}
                                  checked={
                                    item.value === periodValue ? true : false
                                  }
                                  onChange={radioChecked}
                                />
                                <label for={item.id}>{item.value}</label>
                              </li>
                            ))}
                          </ul>
                        </div>
                        {periodValue === "Custom" && (
                          <div className="date-range-wrap">
                            <p className="text">
                              Please select a custom date range :
                            </p>

                            <div className="datepicker">
                              <div className="bs-form">
                                <div className="form-group">
                                  <label className="form-label">From</label>
                                  <div className="field-wrap">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        className="form-control date-picker"
                                        variant="outlined"
                                        format="DD-MM-YYYY"
                                        value={dayjs(filter.customStartDate)}
                                        onChange={(e) => setStartDate(dayjs(e))}
                                        open={openStartCalendar}
                                        onClose={() =>
                                          setOpenStartCalendar(false)
                                        }
                                        slotProps={{
                                          textField: {
                                            onClick: () =>
                                              setOpenStartCalendar(true),
                                            autoComplete: "off",
                                          },
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="bs-form">
                                <div className="form-group">
                                  <label className="form-label">To</label>
                                  <div className="field-wrap">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        className="form-control date-picker"
                                        variant="outlined"
                                        format="DD-MM-YYYY"
                                        value={dayjs(filter.customEndDate)}
                                        onChange={(e) => setEndDate(dayjs(e))}
                                        open={openEndCalendar}
                                        onClose={() =>
                                          setOpenEndCalendar(false)
                                        }
                                        slotProps={{
                                          textField: {
                                            onClick: () =>
                                              setOpenEndCalendar(true),
                                            autoComplete: "off",
                                          },
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {dateErrorMsg && (
                              <div className="cm-error">
                                Please select both From & To Date
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="action-wrap">
                    <button
                      className="btn btn-primary"
                      onClick={handleApplyFilter}
                    >
                      APPLY FILTERS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter2;
