import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useLoginViewModel = () => {
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();

    const handleLogin = () => {
        instance.loginPopup().catch((e) => {
        });
    };

    useEffect(() => {
        if (accounts.length > 0) {
            navigate('/');
        }
    }, [accounts]);
    return {
        handleLogin
    }
}

export default useLoginViewModel