import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { CancelButton } from "./CancelButton";

// DateSelector Component
export const DateSelector = ({ onClick }) => (
  <div className="date-cont-wrap">
    <div className="date-wrap">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker className="form-control date-picker" variant="outlined" />
      </LocalizationProvider>
    </div>
    <CancelButton onClick={onClick} />
  </div>
);
