// @ts-nocheck
import { lazy, Suspense } from "react";
import Loader from "../common/components/loader/Loader";
const Home = lazy(() => import("../module/home/view"));
// const Home = lazy(() => import("@module/home/view"));
const TrainingForToday = lazy(() => import("../module/trainingForTheDay/view"));
const DetailAgentView = lazy(() => import("../module/detailAgentView/view"));
const TrainingSessionPage = lazy(() =>
  import("../module/trainingSessionPage/view")
);
const FormPage = lazy(() => import("../module/formPage/view"));
const ManageRecruitment = lazy(() => import("../module/manageRecruitment/view"));
const CandidateList = lazy(() => import("../module/candidateList/view"));
const TccSolution = lazy(() => import("../module/tccSolution/view"));

export const authProtectedStandardRoutes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/training-for-today",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <TrainingForToday />
      </Suspense>
    ),
  },
  {
    path: "/detail-agent-view",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <DetailAgentView />
      </Suspense>
    ),
  },
  {
    path: "/all-training-session",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <TrainingSessionPage />
      </Suspense>
    ),
  },
  {
    path: "/create-batch",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <FormPage />
      </Suspense>
    ),
  },
  {
    path: "/update-batch",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <FormPage />
      </Suspense>
    ),
  },
  {
    path: "/manage-recruitment",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <ManageRecruitment />
      </Suspense>
    ),
  },
  {
    path: "/candidate-list",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <CandidateList />
      </Suspense>
    ),
  },


];
export const authProtectedTccRoutes = [
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader fullpage />}>
        <TccSolution />
      </Suspense>
    ),
  },
];

