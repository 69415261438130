import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../../assets/images/ed-logo.png";
// import logo from "@assets/images/logo.webp";

export function DrawerMenuHeader(props) {
  return (
    <div className="header">
      <div className="img-wrap">
        <NavLink
          className="menu-link"
          to={"/"}
          onClick={props.toggleDrawer("left", false)}
        >
          <img src={logo} alt="logo image" />
        </NavLink>
      </div>
      <button className="btn-close" onClick={props.toggleDrawer("left", false)}>
        <span className="icon icon-Close"></span>
      </button>
    </div>
  );
}
