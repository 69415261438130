// @ts-nocheck
// useApi.js
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const scope = [`${process.env.REACT_APP_AZURE_SCOPES}`];

const getAccessTokenRequest = (accounts) => {
  if (accounts && accounts.length > 0) {
    localStorage.setItem("account", JSON.stringify(accounts[0]));
  }

  const ac =
    JSON.parse(localStorage.getItem("account")) ||
    (accounts.length > 0 ? accounts[0] : null);

  return {
    scopes: scope,
    account: ac,
  };
};

async function fetchData(url, headers) {
  const response = await axios.get(url, { headers });
  return response.data;
}
async function postData(url, data, headers) {
  const response = await axios.post(url, data, { headers });
  return response.data;
}
async function patchData(url, data, headers) {
  const response = await axios.patch(url, data, { headers });
  return response.data;
}
async function putData(url, data, headers) {
  const response = await axios.put(url, data, { headers });
  return response.data;
}
async function deleteData(url, headers) {
  const response = await axios.delete(url, { headers });
  return response.data;
}

export function useGet(name, endpoint, action) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const [queryParams, setQueryParams] = useState({});

  const accessTokenRequest = getAccessTokenRequest(accounts);

  return {
    query: useQuery(
      [name, endpoint, queryParams],
      async () => {
        try {
          const response = await instance.acquireTokenSilent(
            accessTokenRequest
          );
          const token = response.accessToken;
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          return fetchData(
            `${BASE_URL}${endpoint}${getQueryString(queryParams)}`,
            headers
          );
        } catch (error) {
          handleRequestError(error, instance, navigate);
        }
      },
      { ...action, retry: 0 }
    ),
  };
}

export function usePost(endpoint, action) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();

  const accessTokenRequest = getAccessTokenRequest(accounts);

  return useMutation(
    async (data) => {
      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        const token = response.accessToken;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return postData(`${BASE_URL}${endpoint}`, data, headers);
      } catch (error) {
        handleRequestError(error, instance, navigate);
      }
    },
    {
      ...action,
      // Additional useMutation options can be configured here
    }
  );
}
export function usePatch(name, endpoint, scope) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();

  const accessTokenRequest = getAccessTokenRequest(accounts);

  return {
    patchData: useMutation(
      async (data) => {
        try {
          const response = await instance.acquireTokenSilent(
            accessTokenRequest
          );
          const token = response.accessToken;
          const headers = {
            Authorization: `Bearer ${token}`,
          };
          return patchData(`${BASE_URL}${endpoint}`, data, headers);
        } catch (error) {
          handleRequestError(error, instance, navigate);
        }
      },
      {
        // Additional useMutation options can be configured here
      }
    ),
  };
}
export function usePut(endpoint, action) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();

  const accessTokenRequest = getAccessTokenRequest(accounts);

  return useMutation(
    async (data) => {
      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        const token = response.accessToken;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return putData(`${BASE_URL}${endpoint}`, data, headers);
      } catch (error) {
        handleRequestError(error, instance, navigate);
      }
    },
    {
      ...action,
      // Additional useMutation options can be configured here
    }
  );
}
export function useDelete(endpoint, action) {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();

  const accessTokenRequest = getAccessTokenRequest(accounts);

  return useMutation(
    async (data) => {
      try {
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        const token = response.accessToken;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        return deleteData(`${BASE_URL}${endpoint}`, headers);
      } catch (error) {
        handleRequestError(error, instance, navigate);
      }
    },
    {
      ...action,
      // // Include your onSuccess callback with query invalidation here
      // onSuccess: (deletedItemId) => {
      //   // Invalidate the query for the deleted item to trigger a refetch
      //   queryClient.invalidateQueries(name); // Use the specified query name
      // },
    }
  );
}

function getQueryString(queryParams) {
  const filteredParams = {};

  for (const key in queryParams) {
    if (
      queryParams[key] !== null &&
      queryParams[key] !== undefined &&
      queryParams[key] !== ""
    ) {
      filteredParams[key] = queryParams[key];
    }
  }

  const queryArray = Object.entries(filteredParams).map(
    ([key, value]) => `${key}=${encodeURIComponent(value)}`
  );

  return queryArray.length ? `?${queryArray.join("&")}` : "";
}

function handleRequestError(error, instance, navigate) {
  if (error.name !== "BrowserAuthError") {
    instance.logoutRedirect();
    localStorage.clear();
  }
  navigate("/login");
}
