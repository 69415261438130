import { useMsal } from "@azure/msal-react";
import Footer from "./common/components/footer/Footer";
import Header from "./common/components/header/Header";
import PrivateRoute from "./config/PrivateRoute";
import Login from "./module/login/view";
import "./styles/sass/style.scss";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "./common/components/loader/Loader";
import NoDataFound from "./common/components/no-data-found/NoDataFound";
import { ErrorModal } from "./common/components/the-error-modal/ErrorModal";
import { Toaster } from "./common/components/toaster/Toaster";
import {
  authProtectedStandardRoutes,
  authProtectedTccRoutes,
} from "./config/authProtectedRoutes";
import { useGet } from "./services/api/useApi";
import { useRouteStore } from "./store/routeStore";

function App() {
  const { accounts, inProgress } = useMsal();
  const location = useLocation();
  const handleTccRoute = useRouteStore((state) => state.handleTccRoute);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const delay = 1;
    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, delay);
    return () => clearTimeout(timer);
  }, []);

  const action = {
    onSuccess: (data) => {
      if (data?.status) handleTccRoute(data?.responseData?.isTCCUser);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: location.pathname === "/login" ? false : true,
  };
  const {
    query: { data, isLoading, isError, isFetched },
  } = useGet(
    "getTccUser",
    `api/TCCSolution/GetTCCUser/${accounts[0]?.username}`,
    action
  );

  return (
    <>
      {isInitialized && (
        <div className="App">
          <Header />
          {isLoading ? (
            <Loader fullpage />
          ) : location.pathname !== "/login" &&
            isFetched &&
            (isError || !data?.status) ? (
            <NoDataFound fullpage name={"Something went wrong"} />
          ) : (
            <main className="main-content">
              <Routes>
                <Route
                  element={
                    <PrivateRoute accounts={accounts} inProgress={inProgress} />
                  }
                >
                  {data?.responseData?.isTCCUser
                    ? authProtectedTccRoutes.map((route, index) => (
                      <Route
                        key={index}
                        exact
                        path={route.path}
                        element={route.element}
                      />
                    ))
                    :
                    data?.responseData?.isTCCUser === false &&
                    authProtectedStandardRoutes.map((route, index) => (
                      <Route
                        key={index}
                        exact
                        path={route.path}
                        element={route.element}
                      />
                    ))}
                </Route>
                <Route exact path="/login" element={<Login />}></Route>
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </main>
          )}
          <Footer />
          <Toaster />
          <ErrorModal />
        </div>
      )}
    </>
  );
}

export default App;
