import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export function ProfileMenu(props) {
  return (
    <div className="profile-menu">
      <div className="profile-content">
        <button
          className="btn-profile"
          onClick={props.profileClick}
          aria-controls={props.profileopen ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={props.profileopen ? "true" : undefined}
        >
          <span className="icon icon-user-octagon"></span>
          {props.getTrainerName}
          <span
            className={`icon icon-arrow-left ${
              props.profileopen ? "arrow-up" : ""
            }`}
          ></span>
        </button>
        <Menu
          anchorEl={props.anchorEl}
          id="account-menu"
          className="profile-menu"
          open={props.profileopen}
          onClose={props.profileClose}
          onClick={props.profileClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
        >
          <MenuItem className="menu-item" onClick={props.handleLogout}>
            Logout
          </MenuItem>
          {/* <MenuItem className="menu-item" onClick={profileClose}>
           My Profile
          </MenuItem>
          <MenuItem className="menu-item" onClick={profileClose}>
           option1
          </MenuItem>
          <MenuItem className="menu-item" onClick={profileClose}>
           option2
          </MenuItem> */}
        </Menu>
      </div>
    </div>
  );
}
