import { Modal } from '@mui/material';
import React from 'react';
import { useGlobalStore } from '../../../store/globalStore';

export const ErrorModal = () => {
    const state = useGlobalStore((state) => state.errorModal);
    const handleToggle = useGlobalStore(
        (state) => state.handleHideError
    );
    return (
        <Modal
            open={state.status}
            onClose={handleToggle}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="cm-modal typ-confirmation"
        >
            <div className="modal-body">
                <div className="header">
                    <h5 className="title">
                        Error
                    </h5>
                    <button className="btn-close" onClick={handleToggle}>
                        <span className="icon icon-Close"></span>
                    </button>
                </div>
                <div className="body">
                    <p className="text">{state.message}</p>
                </div>
            </div>
        </Modal>
    )
}
