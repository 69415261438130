import { useLocation } from "react-router-dom";
import fb from "../../../assets/images/fb.svg";
// import fb from "@assets/images/fb.svg";
import insta from "../../../assets/images/insta.svg";
import twit from "../../../assets/images/twit.svg";
import youtube from "../../../assets/images/youtube.svg";
import "./footer.scss";

function Footer() {
  const location = useLocation();
  return (
    <>
      <footer
        className={`bs-footer ${
          location.pathname === "/" && "typ-footer-home"
        } ${location.pathname === "/login" && "cm-hide"}`}
      >
        <div className="cm-container">
          <div className="policy-social-wrap">
            <div className="policy-wrap">
              <ul className="list">
                <li className="item">
                  <a
                    href="https://www.edelweisstokio.in/terms-and-condition"
                    target="_blank"
                  >
                    Term of Service
                  </a>
                </li>
                <li className="item">
                  <a
                    href="https://www.edelweisstokio.in/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="social-wrap">
              <ul className="list">
                <li className="item">
                  <a
                    href="https://www.facebook.com/EdelweissTokioLife"
                    target="_blank"
                  >
                    <img src={fb} alt="facebook icon" />
                  </a>
                </li>
                <li className="item">
                  <a
                    href="https://www.instagram.com/edelweiss_tokio/"
                    target="_blank"
                  >
                    <img src={insta} alt="instagram icon" />
                  </a>
                </li>
                <li className="item">
                  <a href="https://twitter.com/edelweiss_tokio" target="_blank">
                    <img src={twit} alt="twitter icon" />
                  </a>
                </li>
                <li className="item">
                  <a
                    href="https://www.youtube.com/channel/UCaRLeU8nlh4pCoukaIl06gw"
                    target="_blank"
                  >
                    <img src={youtube} alt="youtube icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
