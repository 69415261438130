import logo from "../../../../assets/images/ed-logo.png";
// import logo from "@assets/images/logo.webp";
import { NavLink } from "react-router-dom";
import { useRouteStore } from "../../../../store/routeStore";

export function HamburgerWithImg(props) {
  const isTccRoute = useRouteStore((state) => state.isTccRoute)

  return (
    <div className="hamburger-img-wrap">
      {isTccRoute !== null &&
        <button className="menu" onClick={props.toggleDrawer("left", true)}>
          <span className="icon icon-menu"></span>
        </button>}
      <div className="img-wrap">
        <NavLink className="menu-link" to={'/'}>
          {/* <span className={`icon ${item.icon}`}></span>
              {item.title} */}
          <img src={logo} alt="logo image" />
        </NavLink>
        {/* <a href="/">
        </a> */}
      </div>
    </div>
  );
}
