// @ts-nocheck
import { create } from "zustand";

export const useCandidateFilterStore = create((set) => ({
  search: "",
  selectedSearch: "",
  searchData: null,
  currentPage: 1,
  perPage: 12,
  totalDataCount: 10,
  totalPages: 10,
  searchOpen: false,
  toggleSearchOpen: (payload) =>
    set((state) => ({ searchOpen: !state.searchOpen })),
  setCurrentPage: (payload) => set((state) => ({ currentPage: payload })),
  setSearch: (payload) => set((state) => ({ search: payload })),
  setSelectedSearch: (payload) => set((state) => ({ selectedSearch: payload })),
  setSearchData: (payload) => set((state) => ({ searchData: payload })),
  resetStore: () =>
    set((state) => ({
      search: "",
      selectedSearch: "",
      searchData: null,
      currentPage: 1,
      perPage: 12,
      totalDataCount: 10,
      totalPages: 10,
      searchOpen: false,
    })),
}));
