import './no-data.scss';

function NoDataFound({ name, fullpage, hideIcon }) {
  return (
    <div className={`no-data-wrap ${fullpage ? 'typ-full' : ''}`}>
      {!hideIcon && <span className="icon icon-no-data"></span>}
      <p className="text">{name}</p>
    </div>

  );
}

export default NoDataFound;
