// @ts-nocheck
import logo from "../../../../assets/images/ed-logo.png";
// import logo from "@assets/images/logo.webp";

const LoginContainer = ({ handleLogin }) => {
  return (
    <section className="cm-section typ-login">
      <div className="cm-container">
        <div className="bs-login">
          <div className="logo-wrap">
            <img src={logo} alt="logo image" />
          </div>
          <div className="content-wrap">
            <div className="login-field-wrap bs-form">
              <div className="action-wrap">
                <button className="btn btn-primary" onClick={handleLogin}>
                  LOGIN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginContainer;
