import React from "react";

export function SubheaderArrowWithPageTitle(props) {
  return (
    <div className="text-wrap">
      {props.pathname !== "/" && (
        <a href="#" className="link" onClick={props.handleGoBack}>
          <span className="icon icon-arrow-left"></span>
        </a>
      )}
      <h1 className="cm-title-1">{props.pageTitle}</h1>
    </div>
  );
}
