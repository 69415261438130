import { Alert, Snackbar, Stack } from '@mui/material';
import React from 'react';
import { useGlobalStore } from '../../../store/globalStore';

export const Toaster = () => {
    const snackbar = useGlobalStore((state) => state.snackbar);
    const handleHideSnackbar = useGlobalStore(
        (state) => state.handleHideSnackbar
    );
    return (
        <div className="snackbar-wrap cm-container">
            <Stack spacing={2} sx={{ width: "30%" }}>
                <Snackbar
                    open={snackbar.status}
                    autoHideDuration={6000}
                    onClose={handleHideSnackbar}
                    className="bs-snackbar"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                >
                    <Alert
                        onClose={handleHideSnackbar}
                        severity="success"
                        sx={{ width: "100%" }}
                        className="msg-wrap"
                    >
                        <p className="text">{snackbar.message}</p>
                    </Alert>
                </Snackbar>
                {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
            </Stack>
        </div>
    )
}
