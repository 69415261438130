// @ts-nocheck
import { InteractionStatus } from '@azure/msal-browser';
import { Navigate, Outlet } from 'react-router';

const PrivateRoute = ({ accounts, inProgress }) => {
    return (
        inProgress === InteractionStatus.None && accounts.length > 0 ?
            <Outlet />
            :
            <Navigate to='/login' />
    )
}

export default PrivateRoute