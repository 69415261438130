import loader from "../../../assets/images/loader.svg";
// import loader from "@assets/images/loader.svg";
import "./loader.scss";

function Loader({ fullpage }) {
  return (
    <div className={`loader-wrap ${fullpage ? 'typ-full' : ''}`}>
      <div className="img-wrap">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
}

export default Loader;
