// @ts-nocheck
import { create } from "zustand";

export const useRecruitmentFilterStore = create((set) => ({
  status: "PaymentPending",
  search: "",
  sortBy: "",
  customStartDate: null,
  customEndDate: null,
  period: "",
  currentPage: 1,
  perPage: 12,
  totalDataCount: 10,
  totalPages: 10,
  setCustomStartDate: (payload) =>
    set((state) => ({ customStartDate: payload })),
  setCustomEndDate: (payload) => set((state) => ({ customEndDate: payload })),
  setCurrentPage: (payload) => set((state) => ({ currentPage: payload })),
  setStatus: (payload) => set((state) => ({ status: payload, currentPage: 1 })),
  setSearch: (payload) => set((state) => ({ search: payload, currentPage: 1 })),
  setPeriod: (payload) => set((state) => ({ period: payload, currentPage: 1 })),
  setSortBy: (payload) => set((state) => ({ sortBy: payload })),
  resetFilter: () =>
    set({
      search: "",
      status: "",
      sortBy: {
        groupA: null,
        groupB: null,
      },
      period: "",
    }),
  resetRecruitmentStore: () =>
    set({
      status: "PaymentPending",
      search: "",
      sortBy: "",
      customStartDate: null,
      customEndDate: null,
      period: "",
      currentPage: 1,
      perPage: 12,
      totalDataCount: 10,
      totalPages: 10,
    }),
}));
