import { useRouteStore } from "../../../../store/routeStore";
import { useUpdateBatchStore } from "../../../../store/updateBatchStore";

export const usePageTitle = (location, globalFilter) => {
  let pageTitle;
  const isTccRoute = useRouteStore((state) => state.isTccRoute)
  const updateBatchData = useUpdateBatchStore((state) => state.updateBatchData);


  if (isTccRoute) {
    switch (location.pathname) {
      case "/":
        pageTitle = "TCC Solution";
        break;
      default:
        pageTitle = "";
    }
  } else {
    switch (location.pathname) {
      case "/":
        pageTitle = "Trainings for Agent";
        break;
      case "/training-for-today":
        pageTitle = "Training sessions";
        break;
      case "/mark-attendance":
        pageTitle = "Mark Attendance for Training";
        break;
      case "/manage-agents-onboard":
        pageTitle = globalFilter.filter ? "Filters" : "Manage Agent Onboardings";
        break;
      case "/detail-agent-view":
        pageTitle = "Detailed Agent View";
        break;
      case "/all-training-session":
        pageTitle = "All Training Sessions";
        break;
      case "/create-batch":
        pageTitle = "Create a New Batch";
        break;
      case "/update-batch":
        pageTitle = "Update Batch";
        break;
      case "/new-application":
        pageTitle = "New Applications";
        break;
      case "/pending-request":
        pageTitle = "Pending Request";
        break;
      case "/ongoing-trainings":
        pageTitle = "Ongoing Trainings";
        break;
      case "/approved-candidates":
        pageTitle = "Approved Candidates";
        break;
      case "/re-exam":
        pageTitle = "Re-Exam";
        break;
      case "/posps":
        pageTitle = "POSPs";
        break;
      case "/new-application-table":
        pageTitle = "Manage Recruitment";
        break;
      case "/manage-recruitment":
        pageTitle = globalFilter.filter ? "Filters" : "Manage Recruitment";
        break;
      case "/load-more-state":
        pageTitle = "Card list";
        break;
      case "/candidate-list":
        pageTitle = "Candidate List";
        break;
      case "/tcc-solution":
        pageTitle = "TCC Solution";
        break;
      default:
        pageTitle = "";
    }
  }

  return { pageTitle };
};
