export const standardMenuLinks = [
  {
    title: "Home",
    link: "/",
    icon: "icon-home-new",
  },
  {
    title: "Trainings for the Day",
    link: "/training-for-today",
    icon: "icon-monitor-recorder",
  },
  {
    title: "Manage Recruitment Leads",
    link: "/manage-recruitment",
    icon: "icon-people",
  },
  {
    title: "Add New Training Batch",
    link: "/create-batch",
    icon: "icon-clipboard-tick",
  },
  {
    title: "Manage Training Batches",
    link: "/all-training-session",
    icon: "icon-briefcase",
  },
  {
    title: "Candidate Details",
    link: "/candidate-list",
    icon: "icon-user-octagon",
  },

];
export const tccMenuLinks = [

  {
    title: "TCC Solution",
    link: "/",
    icon: "icon-file-down",
  },
];
