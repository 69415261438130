import React from "react";
import { NavLink } from "react-router-dom";

export function DrawerMenuBody(props) {
  return (
    <div className="body">
      <ul className="menu-list">
        {props.menuLinks.map((item, index) => (
          <li
            key={index}
            className={`item ${props.pathname === item.link ? "active" : ""}`}
            onClick={props.toggleDrawer("left", false)}
          >
            <NavLink className="menu-link" to={item.link}>
              <span className={`icon ${item.icon}`}></span>
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
