import { create } from 'zustand';

export const useGlobalStore = create((set) => ({
    header: {
        filter: false,

    },
    otherToggles: {
        applicationType: true
    },
    searchOpen: false,
    menuOpen: false,
    menuSelected: '',
    noDataModel: false,
    assignBatchStatus: false,
    snackbar: {
        status: false,
        message: ''
    },
    errorModal: {
        status: false,
        message: ''
    },
    handleShowError: (payload) => set((state) => ({
        errorModal: {
            status: true,
            message: payload ? payload : 'Something went wrong!'
        }
    })),
    handleHideError: () => {
        set((state) => ({
            errorModal: {
                status: false,
                message: ''
            }
        }))
    },
    handleShowSnackbar: (payload) => set((state) => ({
        snackbar: {
            status: true,
            message: payload
        }
    })),
    handleHideSnackbar: () => {
        set((state) => ({
            snackbar: {
                status: false,
                message: ''
            }
        }))
    },
    handleAssignBatchStatus: () => set((state) => ({ assignBatchStatus: !state.assignBatchStatus })),
    toggleSearchOpen: () => set((state) => ({ searchOpen: !state.searchOpen })),
    toggleNoDataModel: () => set((state) => ({ noDataModel: !state.noDataModel })),
    resetToggleApplicationType: () => set({ otherToggles: { applicationType: true } }),
    toggleApplicationType: () => set((state) => ({ otherToggles: { applicationType: !state.otherToggles.applicationType } })),

    toggleHeaderFilter: (payload) => set((state) => ({ header: { filter: payload } })),
    resetHeaderFilter: () => set({ header: { filter: false } }),
}))