import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";

// SearchInput Component
export const SearchInput = ({ value, onChange, filterData }) => (
  <Autocomplete
    id="search-demo"
    className="input-text"
    options={filterData?.searchData || []}
    classes={"test"}
    clearOnBlur={false}
    autoSelect={true}
    groupBy={(option) => "Recent Search"}
    onChange={(e, value, _) => {
      filterData?.setSearch(value?.prospectsName);
      filterData?.setSelectedSearch(value?.prospectsName);
      filterData.setCurrentPage(1);
    }}
    getOptionLabel={(option) => option?.prospectsName}
    sx={{ width: 300 }}
    renderInput={(params, searchText) => (
      <TextField
        {...params}
        placeholder="search here"
        value={searchText}
        onChange={onChange}
      />
    )}
  />
);
