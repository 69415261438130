import { useMsal } from "@azure/msal-react";
import Drawer from "@mui/material/Drawer";
import { useCandidateFilterStore } from "../../../store/candidateFilterStore";
// import { useCandidateFilterStore } from "@store/candidateFilterStore";
import { useGlobalStore } from "../../../store/globalStore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRouteStore } from "../../../store/routeStore";
import Filter2 from "../filters/Filter";
import "../header/header.scss";
import { CancelButton } from "./component/CancelButton";
import { DateSelector } from "./component/DateSelector";
import { DrawerMenuBody } from "./component/DrawerMenuBody";
import { DrawerMenuHeader } from "./component/DrawerMenuHeader";
import { FilterButton } from "./component/FilterButton";
import { HamburgerWithImg } from "./component/HamburgerWithImg";
import { ProfileMenu } from "./component/ProfileMenu";
import { SearchButton } from "./component/SearchButton";
import { SearchInput } from "./component/SearchInput";
import { SubheaderArrowWithPageTitle } from "./component/SubheaderArrowWithPageTitle";
import { standardMenuLinks, tccMenuLinks } from "./data/menuLinks";
import { usePageTitle } from "./viewModel/usePageTitle";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const globalFilter = useGlobalStore((state) => state.header);
  const isTccRoute = useRouteStore((state) => state.isTccRoute);

  const toggleHeaderFilter = useGlobalStore(
    (state) => state.toggleHeaderFilter
  );
  const filter = useCandidateFilterStore((state) => state);
  const { pageTitle } = usePageTitle(location, globalFilter);
  const [winW, setWinW] = useState(0);
  const [searchText, setSearchText] = useState(filter?.search);
  const [timeoutId, setTimeoutId] = useState(null);

  const [state, setState] = useState({
    top: false,
    left: false,
  });

  // const [searchOpen, setSearchOpen] = useState(true);
  const [dateSearchOpen, setDateSearchOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const profileopen = Boolean(anchorEl);
  const { instance, accounts } = useMsal();
  const getTrainerName = accounts[0]?.name;

  useEffect(() => {
    const isBrowser = window !== "undefined";
    if (isBrowser) {
      setWinW(window?.outerWidth);
    }
  }, []);

  const handleClick = () => {
    filter.toggleSearchOpen();
    filter?.setSearch("");
    filter.setSelectedSearch("");
  };

  const dateClick = () => {
    setDateSearchOpen(!dateSearchOpen);
  };

  const profileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const profileClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    instance.logoutRedirect();
    localStorage.clear();
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    toggleDrawer("top", false)(e);
    if (navigation.canGoBack) {
      navigate(-1);
    } else {
      window.close();
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    if (anchor === "top" && open) {
      toggleHeaderFilter(true);
    } else {
      toggleHeaderFilter(false);
    }
  };

  const handleSearchText = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      filter?.setSearch(newValue);
    }, 300);
    setTimeoutId(newTimeoutId);
  };

  return (
    <>
      {/* header  */}
      <header
        className={`bs-header ${
          location.pathname === "/login" ? "cm-hide" : ""
        }`}
      >
        {/* main header  */}
        <div className="main-header">
          <div className="menu-wrap cm-container">
            <HamburgerWithImg toggleDrawer={toggleDrawer} />
            <ProfileMenu
              anchorEl={anchorEl}
              profileopen={profileopen}
              profileClick={profileClick}
              getTrainerName={getTrainerName}
              profileClose={profileClose}
              handleLogout={handleLogout}
            />
          </div>
        </div>
        {/* sub header  */}
        {isTccRoute !== null && (
          <div className="sub-header">
            <div className="cm-container">
              <div
                className={`bs-icon-text-sort-wrap ${
                  filter.searchOpen ? "typ-search-full" : ""
                } ${!dateSearchOpen ? "typ-search-full" : ""}`}
              >
                <SubheaderArrowWithPageTitle
                  pathname={location.pathname}
                  handleGoBack={handleGoBack}
                  pageTitle={pageTitle}
                />
                <div className="sort-wrap">
                  {location.pathname === "/manage-recruitment" && (
                    <>
                      {globalFilter.filter ? (
                        <CancelButton onClick={toggleDrawer("top", false)} />
                      ) : (
                        <FilterButton
                          onClick={toggleDrawer(
                            winW < 768 ? "right" : "top",
                            true
                          )}
                          isHidden={!dateSearchOpen}
                        />
                      )}
                      {!dateSearchOpen && <DateSelector onClick={dateClick} />}
                    </>
                  )}
                  {location.pathname === "/candidate-list" && (
                    <>
                      <SearchButton
                        onClick={handleClick}
                        isHidden={filter.searchOpen}
                      />
                      {filter.searchOpen && (
                        <div className="search-input-wrap">
                          <div className="search-cont">
                            <span className="icon icon-search"></span>
                            <SearchInput
                              value={searchText}
                              onChange={handleSearchText}
                              filterData={filter}
                            />
                          </div>
                          <CancelButton onClick={handleClick} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      {/* drawer */}
      {isTccRoute !== null && (
        <div>
          {[`${winW < 768 ? "right" : "top"}`].map((anchor) => (
            <React.Fragment key={anchor}>
              {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                className={`filter-drawer ${
                  winW < 768 ? "typ-right-drawer" : ""
                }`}
              >
                <Filter2 toggleDrawer={toggleDrawer} />
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      )}
      {/* menu drawer */}
      {isTccRoute !== null && (
        <div>
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                className="menu-drawer"
              >
                <div className="menu-title-list-wrap">
                  <DrawerMenuHeader toggleDrawer={toggleDrawer} />
                  <DrawerMenuBody
                    menuLinks={isTccRoute ? tccMenuLinks : standardMenuLinks}
                    pathname={location.pathname}
                    toggleDrawer={toggleDrawer}
                  />
                </div>
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
}

export default Header;
